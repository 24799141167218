<template>
  <div>
    <h2 class="text-center mb-4">Маршрутные листы</h2>

    <v-tabs @change="tabChangeHandler">
      <v-tab v-for="region in regions" :key="region.id">
        {{ region.name }}
      </v-tab>
    </v-tabs>

    <v-card class="pa-4 mb-4">
      <v-row>
        <v-col cols="12" md="2">
          <Datepicker
            :classes="['pt-0']"
            v-model="query.verification_date"
            @clear="query.verification_date = null"
            title="Дата поверки"
            clearable
            hide-details
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            class="pt-0"
            v-model="query.verifier_id"
            :items="filteredVerifiers.map((item) => ({  value: item.id, text: item.name }))"
            clearable
            hide-details
            label="Поверитель"
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            class="pt-0"
            v-model="query.mechanic_id"
            :items="filteredMechanics.map((item) => ({ value: item.id, text: item.name }))"
            clearable
            hide-details
            label="Слесарь"
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-select
            v-model="query.type_id"
            label="Тип"
            class="pt-0"
            :items="[{ value: 1, text: 'Газ' }, { value: 2, text: 'Вода' }]"
            clearable
            hide-details
          />
        </v-col>
        <v-col cols="12" md="4" class="text-right">
          <v-btn @click="addRouteDrawer = true" color="green lighten-1" dark>
            <v-icon> mdi-plus </v-icon>
            Добавить новый
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-row>
      <template v-if="loading">
        <v-col cols="12" md="6" v-for="route in 4" :key="route.id">
          <v-skeleton-loader type="card" />
        </v-col>
      </template>

      <template v-else>
        <v-col cols="12" md="6" v-for="route in filteredRoutes" :key="route.id">
          <v-data-table
            :items="route.verifications"
            :hide-default-header="true"
            :show-select="false"
            :loading="false"
            fixed-header
            height="360px"
            hide-default-footer
            item-key="name"
            class="elevation-1"
            dense
          >
            <template v-slot:top>
              <v-row no-gutters class="pa-2">
                <v-col>
                  <p class="subtitle-2 mb-0 font-weight-black">
                    {{ route.name }} {{ route.date }}
                  </p>

                  <p class="body-2 mb-0 font-weight-bold">
                    поверок:
                    {{
                      route.verifications &&
                      route.verifications.filter((v) => v.job_type.id === 1)
                        .length
                    }}
                    замен:
                    {{
                      route.verifications &&
                      route.verifications.filter((v) => v.job_type.id === 2 || v.job_type.id === 3)
                        .length
                    }}
                  </p>
                  <p class="body-2 mb-0 font-weight-bold"></p>
                  <p class="body-2 mb-0 font-weight-bold">
                    Всего:
                    {{ route.verifications && route.verifications.length }}
                  </p>
                </v-col>

                <v-col cols="3">
                  <v-select
                    @change="reassingVerifier($event, route)"
                    :value="route.verifier_id"
                    :items="
                      filteredVerifiers.map((item) => ({
                        value: item.id,
                        text: item.name,
                      }))
                    "
                    class="mr-1"
                    label="Поверитель"
                    clearable
                    hide-details
                    outlined
                    dense
                  />
                </v-col>

                <v-col cols="3">
                  <v-select
                    @change="reassingMechanic($event, route)"
                    :value="route.mechanic_id"
                    :items="
                      filteredMechanics.map((item) => ({
                        value: item.id,
                        text: item.name,
                      }))
                    "
                    label="Слесарь"
                    clearable
                    hide-details
                    outlined
                    dense
                  />
                </v-col>

                <v-col cols="2">
                  <v-text-field
                    label="Дистанция"
                    @keyup="setExpectedDistance(route)"
                    v-model="route.expected_distance"
                    clearable
                    hide-details
                    outlined
                    dense
                    v-mask="'####'"
                  />
                </v-col>

                <v-col cols="2" class="text-right">
                  <v-btn
                    color="green lighten-1"
                    class="mr-2"
                    icon
                    :href="`${host}/route/${route.id}/download_excel`"
                    target="_blank"
                  >
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-btn>
                  <v-btn
                    color="blue lighten-1"
                    icon
                    :href="`${host}/route/${route.id}/download`"
                    target="_blank"
                  >
                    <v-icon>mdi-printer-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-slot:header>
              <thead>
                <tr>
                  <th></th>
                  <th>Статус</th>
                  <th>Адрес</th>
                  <th>Цена</th>
                  <th>Комментарий</th>
                </tr>
              </thead>
            </template>

            <template v-slot:body="{ items }">
              <draggable
                tag="tbody"
                handle=".drug-handle"
                v-model="sortedVerifications[route.id]"
                group="verifications"
                @start="dragging = true"
                @end.stop="(e) => onStopDrag(e, route.id)"
              >
                <tr
                  v-for="(item, index) in items"
                  :key="index"
                  :style="{
                    'background-color':
                      item.meter_status_id === 2 ? '#FFEBEE' : '#E8F5E9',
                  }"
                >
                  <td style="width: 20px">
                    <v-icon class="drug-handle">mdi-swap-vertical</v-icon>
                  </td>
                  <td style="width: 16%">
                    {{
                      meter_statuses.find(
                        (ms) => ms.id === item.meter_status_id
                      ).name || null
                    }}
                  </td>

                  <td style="width: 38%">
                    {{ item.city }}, {{ item.address }}

                    <v-btn
                      icon
                      color="primary"
                      @click.stop="copyAddress(`${item.city}, ${item.address}`)"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </td>
                  <td style="width: 22%">
                    {{ item.price }} <br />
                    {{ item.price_comment }}
                  </td>
                  <td style="width: 30%">{{ item.comment }}</td>
                </tr>
              </draggable>
            </template>

            <template v-slot:no-data> Нет данных! </template>

            <template v-slot:no-results> Нет результатов поиска! </template>

            <template v-slot:footer>
              <div class="d-flex align-center justify-space-between pa-2 px-4">
                <div class="d-flex align-center">
                  <span class="body-2">Выполнено:</span>
                  <v-badge inline left tile color="green lighten-1">
                    <span class="body-2">метролог</span>
                  </v-badge>
                  <v-badge inline left tile color="green lighten-1">
                    <span class="body-2">диспетчер</span>
                  </v-badge>
                  <v-badge inline left tile color="red lighten-1">
                    <span class="body-2">бухгалтер</span>
                  </v-badge>
                </div>
                <div class="text-right">
                  <v-btn
                    color="error"
                    class="mr-2"
                    icon
                    @click="remove(route.id)"
                  >
                    <v-icon> mdi-delete-outline </v-icon>
                  </v-btn>

                  <v-btn
                    color="blue lighten-1"
                    icon
                    @click="$router.push(`/manager/routes/${route.id}`)"
                  >
                    <v-icon> mdi-folder-open-outline </v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </template>
    </v-row>

    <v-navigation-drawer
      v-model="addRouteDrawer"
      absolute
      right
      temporary
      width="40%"
    >
      <v-card class="pa-4" flat>
        <RouteForm />
      </v-card>
    </v-navigation-drawer>

    <v-btn color="blue lighten-1" dark fixed left bottom @click="goPrevDay">
      <v-icon>
        mdi-arrow-left-bold-outline
      </v-icon>
      Вчера
      <v-icon>
        mdi-calendar-today
      </v-icon>
    </v-btn>

    <v-btn color="blue lighten-1" dark fixed right bottom @click="goNextDay">
      <v-icon>
        mdi-calendar-today
      </v-icon>
      завтра
      <v-icon>
        mdi-arrow-right-bold-outline
      </v-icon>
    </v-btn>

    <DeleteConfirmation
      :visible="deleteConfirmationVisible"
      v-on:confirm="removeConfirmed"
      v-on:decline="removeDeclined"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { HOST } from '@/config'
import { ListsService, RoutesService } from '@/common/api.service'
import DeleteConfirmation from "@/components/Modals/DeleteConfirmation"
import Datepicker from "@/components/Datepicker"
import RouteForm from "@/components/Routes/Form"
import VueMask from 'v-mask'
import draggable from 'vuedraggable'
Vue.use(VueMask)

export default {
  name: 'Routes',

  components: {
    RouteForm,
    Datepicker,
    DeleteConfirmation,
    draggable,
  },

  data () {
    return {
      loading: false,
      addRouteDrawer: false,
      // Фильтр по списку МЛ
      query: {
        region_id: null,
        // verification_date: "", // new Date().toISOString().substr(0, 10),
        verification_date: new Date().toISOString().substr(0, 10),
        verifier_id: null,
        mechanic_id: null,
        type_id: null,
      },
      routes: [], // маршрутные листы
      sortedVerifications: [], // маршрутные листы отсортированные
      dragging: false,
      regions: [],
      verifiers: [], // поверители
      mechanics: [], // механики
      meter_statuses: [],
      // Delete logic
      deleteConfirmationVisible: false,
      deleteVerificationId: null,
      host: HOST,
      timeout: null
    }
  },

  methods: {
    // Копирование адреса
    copyAddress (address) {
      navigator.clipboard.writeText(address)
    },
    async onStopDrag (e, routeId) {
      try {
        await RoutesService.reorderVerifications(routeId, e.oldIndex, e.newIndex)
      } catch (e) {
        console.error("err", e)
      } finally {
        this.loading = false
      }
    },
    /**
     * Привязывает запись слесаря к МЛ
     * @param {Number} mechanic_id - id слесаря
     * @param route
     */
    async reassingMechanic(mechanic_id, route) {
      this.loading = true
      try {
        await RoutesService.update(route.id, {
          ...route,
          mechanic_id: mechanic_id,
        })
        const response = await RoutesService.get(false, this.query)
        this.routes = response.routes
      } catch (e) {
        console.error("err", e)
      } finally {
        this.loading = false
      }
    },

    /**
     * Привязывает запись поверителя к МЛ
     * @param {Number} verifier_id - id поверителя
     * @param route
     */
    async reassingVerifier(verifier_id, route) {
      this.loading = true
      try {
        await RoutesService.update(route.id, {
          ...route,
          verifier_id: verifier_id,
        })
        const response = await RoutesService.get(false, this.query)
        this.routes = response.routes
      } catch (e) {
        console.error("err", e)
      } finally {
        this.loading = false
      }
    },

    /**
     * Сохранение ожидаемой дистанции.
     */
    setExpectedDistance(route) {
      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        RoutesService.update(route.id, {
          ...route,
          expected_distance: route.expected_distance
        })
      }, 1000)
    },

    // Нажата кнопка 'удалить'
    // Сохраняем ID и показываем предупреждение
    remove (id) {
      this.deleteVerificationId = id
      this.deleteConfirmationVisible = true
    },

    // Юзер подтвердил удаление
    async removeConfirmed () {
      this.deleteConfirmationVisible = false
      this.loading = true
      try {
        await RoutesService.delete(this.deleteVerificationId)
        const response = await RoutesService.get(false, this.query)
        this.routes = response.routes
      } finally {
        this.loading = false
      }
    },

    // Юзер передумал удалять
    removeDeclined () {
      this.deleteConfirmationVisible = false
    },

    //
    tabChangeHandler (index) {
      if (!this.regions.length) return
      this.query.region_id = this.regions[index].id
    },

    // Предыдущий день
    goPrevDay () {
      const today = this.query.verification_date
        ? new Date(this.query.verification_date)
        : new Date()

      const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000)

      this.query.verification_date = new Date(yesterday)
        .toISOString()
        .substr(0, 10)
    },

    // Предыдущий день
    goNextDay () {
      const today = this.query.verification_date
        ? new Date(this.query.verification_date)
        : new Date()

      const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000)

      this.query.verification_date = new Date(tomorrow)
        .toISOString()
        .substr(0, 10)
    },
  },

  computed: {
    //
    filteredRoutes() {
      return this.routes
        .filter((route) => {
          if (this.query.verifier_id) {
            return route.verifier_id === this.query.verifier_id
          }
          return route
        })
        .filter((route) => {
          if (this.query.mechanic_id) {
            return route.mechanic_id === this.query.mechanic_id
          }
          return route
        })
        .filter((route) => {
          if (this.query.region_id) {
            return route.region_id === this.query.region_id
          }
          return route
        })
        .filter((route) => {
          //
          if (this.query.verification_date) {
            return route.date === this.query.verification_date
          }
          return route
        })
    },

    // Отфильтрованные по региону слесаря
    filteredMechanics() {
      if (!this.query.region_id) {
        return []
      }

      return this.mechanics.filter(
        (mechanic) => this.query.region_id === mechanic.region_id
      )
    },

    // Отфильтрованные по региону поверители
    filteredVerifiers() {
      if (!this.query.region_id) {
        return []
      }
      return this.verifiers.filter(
        (verifier) => this.query.region_id === verifier.region_id
      )
    }
  },

  beforeMount() {
    this.loading = true

    ListsService.get(['regions', 'meter_statuses', 'workers'])
      .then(({ data }) => {
        this.regions = data.regions
        this.meter_statuses = data.meter_statuses
        this.verifiers = data.workers.filter((w) => w.worker_type_id === 1)
        this.mechanics = data.workers.filter((w) => w.worker_type_id === 2)
      })
  },

  watch: {
    query: {
      handler (newValue, oldValue) {
        if (oldValue.region_id !== newValue.region_id) {
          this.query.verifier_id = null
          this.query.mechanic_id = null
          this.query.type_id = null
        }

        RoutesService.get(false, this.query)
          .then((response) => {
            this.routes = response.routes
            this.loading = false
          })
      },

      deep: true
    }
  }
}
</script>

<style>
.drug-handle {
  cursor: move
}
</style>
